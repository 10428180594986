import './polyfills/scrollingElement';

import './components/Mainnav';
import './components/Hero';
import './components/HomeVideo';
import './components/ApplicationForm';
import './components/Newsletter';
import './components/JobsInterested';
import './components/WorksCarousel';
import './components/WorkThumb';
import './components/NewsThumb';
import './components/ClientListItem';
import './components/Jobs';
import './components/Error';
import './components/MapWeather';

// Modules
import './components/Image';
import './components/ImagesPicture';
import './components/ImagesVideo';
import './components/ImagesLoopImages';
import './components/MemoryFigure';
import './components/BrowserContainer';
import './components/Slider';
import './components/Video';
import './components/Werbebande';

// import './components/DEBUG';

(() => {
  if ('ontouchstart' in document.documentElement) {
    document.body.classList.add('is-touch');
  }
  const { userAgent } = window.navigator;
  if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
    document.body.classList.add('is-ie');
  }
})();
