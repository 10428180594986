import Picture from './Picture';

class JobsItemPicture extends Picture {
  constructor(element) {
    super(element);

    const jobsItemPicture = this;

    function checkIfContentShouldLoad() {
      if (jobsItemPicture.willSoonBeInView) {
        document.removeEventListener('scroll', checkIfContentShouldLoad);
        document.removeEventListener('resize', checkIfContentShouldLoad);
        jobsItemPicture.loadImage();
      }
    }

    document.addEventListener('scroll', checkIfContentShouldLoad, { passive: true });
    document.addEventListener('resize', checkIfContentShouldLoad);
    checkIfContentShouldLoad();
  }
}

[...document.querySelectorAll('.jobs li picture')].forEach((pictureElement) => {
  new JobsItemPicture(pictureElement);
});
