import Component from './Component';
import Picture from './Picture';


class Slider extends Component {
  constructor(element) {
    super(element);

    const slider = this;
    const prevBttnElement = element.querySelector('.bttn-prev');
    const nextBttnElement = element.querySelector('.bttn-next');
    const containerElement = element.querySelector('.slider__container');
    const ulElement = element.querySelector('ul');

    if (prevBttnElement && nextBttnElement) {
      this.prevBttnElement = prevBttnElement;
      this.nextBttnElement = nextBttnElement;
    }
    this.ulElement = ulElement;
    this.liElements = this.ulElement.querySelectorAll('li');
    this.firstLiElement = this.liElements[0];
    this.firstFigureElement = this.firstLiElement.querySelector('figure');

    this.isMouseDown = false;
    this.isSwiping = false;
    this.isScrolling = false;
    this.mouseXStart = 0;
    this.mouseYStart = 0;
    this.mouseXDelta = 0;
    this.mouseYDelta = 0;
    this.currentImage = 0;
    this.imagesCount = this.ulElement.childElementCount;

    function checkIfContentShouldLoad() {
      if (slider.willSoonBeInView) {
        setTimeout(() => {
          slider.loadItem(0);
        }, 100); // make async
        setTimeout(() => {
          slider.loadItem(1);
        }, 200); // make async
        window.removeEventListener('scroll', checkIfContentShouldLoad, { passive: true });
        window.removeEventListener('resize', checkIfContentShouldLoad);
      }
    }
    function onMouseDown(event) {
      element.classList.add('is-grabbing');
      if (!(event.target !== prevBttnElement || event.target !== nextBttnElement)) {
        event.preventDefault();
      }
      if (event.type !== 'mousedown' || (event.which === 1 && !event.ctrlKey && !event.metaKey && !event.altKey)) {
        slider.isMouseDown = true;
        slider.mouseXStart = (event.pageX ?? event.touches[0].pageX);
        slider.mouseYStart = (event.pageY ?? event.touches[0].pageY);
      }
    }
    function onMouseMove(event) {
      slider.onMouseMove(event);
    }
    function onMouseUp(event) {
      element.classList.remove('is-grabbing');
      slider.onMouseUp(event);
    }
    function onKeydown(event) {
      if (slider.isInView) {
        if (event.code === 'ArrowRight') {
          slider.nextImage();
        } else if (event.code === 'ArrowLeft') {
          slider.prevImage();
        }
      }
    }
    function onPrevBttnClick() {
      slider.prevImage();
    }
    function onNextBttnClick() {
      slider.nextImage();
    }

    // the class is added in the loadItem() method
    [...slider.liElements].forEach((liElement) => {
      // there could be multiple picture elements when there are overlay images
      const pictures = [];
      const pictureElements = liElement.querySelectorAll('picture');
      [...pictureElements].forEach((pictureElement) => {
        pictures.push(new Picture(pictureElement));
      });
      liElement.pictures = pictures;
    });

    // add event listeners if slider has buttons
    if (prevBttnElement && nextBttnElement) {
      containerElement.addEventListener('mousedown', onMouseDown);
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      containerElement.addEventListener('touchstart', onMouseDown, { passive: false });
      window.addEventListener('touchmove', onMouseMove, { passive: false });
      window.addEventListener('touchend', onMouseUp);
      window.addEventListener('keydown', onKeydown);
      prevBttnElement.addEventListener('click', onPrevBttnClick);
      nextBttnElement.addEventListener('click', onNextBttnClick);

      window.addEventListener('scroll', checkIfContentShouldLoad, { passive: true });
      this.showOverlayImages(0); // make sure overlay images of first slide item is visible
      window.addEventListener('resize', checkIfContentShouldLoad);

      this.updateButtons();
      setTimeout(checkIfContentShouldLoad, 400); // wait a few miliseconds to load content
    }
  }

  hideButtons() {
    if (this.prevBttnElement && this.nextBttnElement) {
      this.prevBttnElement.classList.add('is-hidden');
      this.nextBttnElement.classList.add('is-hidden');
    }
  }

  onMouseMove(event) {
    if (this.isMouseDown && typeof this.moveUlElement === 'function') {
      this.mouseXDelta = (event.pageX ?? event.touches[0].pageX) - this.mouseXStart;
      this.mouseYDelta = (event.pageY ?? event.touches[0].pageY) - this.mouseYStart;

      if (event.type === 'touchmove') {
        // prevent movement when scrolling on touch device
        if (this.isSwiping) {
          this.moveUlElement();
          // prevent scrolling when swiping on touch device
          event.preventDefault();
        }

        // set swiping or scrolling to true
        if (!this.isSwiping && !this.isScrolling) {
          console.log(this.mouseXDelta);
          if ((this.mouseXDelta > 1 || this.mouseXDelta < -1)) {
            this.isSwiping = true;
          } else if (this.mouseYDelta > 1 || this.mouseYDelta < -1) {
            this.isScrolling = true;
          }
        }
      } else {
        this.moveUlElement();
      }
    }
  }

  onMouseUp(event) {
    if (this.isMouseDown && typeof this.slide === 'function') {
      if (this.moveDelta > 40) {
        this.prevImage();
      } else if (this.moveDelta < -40) {
        this.nextImage();
      } else {
        this.slide();
      }

      if (event.type === 'touchend' && (this.moveDelta > 40 || this.moveDelta < -40)) {
        // user knows how to navigate on touchscreen so we can hide to buttons
        this.hideButtons();
      }

      // reset variables
      this.isMouseDown = false;
      this.isScrolling = false;
      this.isSwiping = false;
      this.mouseXStart = 0;
      this.mouseYStart = 0;
      this.mouseXDelta = 0;
      this.mouseYDelta = 0;
    }
  }

  get moveDelta() {
    let moveDelta = this.mouseXDelta * 0.9;
    if (this.currentImage === 0 && moveDelta > 0) {
      moveDelta = Math.sqrt(moveDelta) * 2;
    }
    if (this.currentImage === this.imagesCount - 1 && moveDelta < 0) {
      moveDelta = Math.sqrt(moveDelta * -1) * -2;
    }
    return moveDelta;
  }

  loadItem(i = 0) {
    if (this.liElements[i]) {
      this.liElements[i].pictures.forEach((picture) => {
        picture.loadImage();
      });
    }
  }

  hideAllOverlayImages(except = 0) {
    const { liElements } = this;
    [...liElements].forEach((liElement) => {
      if (liElement !== this.liElements[except] && liElement.querySelector('.slider__overlay-images')) {
        liElement.querySelector('.slider__overlay-images').classList.add('is-hidden');
      }
    });
  }

  prevImage() {
    if (this.currentImage - 1 >= 0) {
      this.currentImage -= 1;
    }
    this.slide();
    this.updateButtons();
  }

  nextImage() {
    if (this.currentImage + 1 <= this.imagesCount - 1) {
      this.currentImage += 1;
    }
    const nextImage = this.currentImage + 1;
    setTimeout(() => {
      this.loadItem(nextImage);
    }, 100); // make async
    this.slide();
    this.updateButtons();
  }

  showOverlayImages(currentImage) {
    const liElement = this.liElements[currentImage];
    if (liElement.querySelector('.slider__overlay-images')) {
      liElement.querySelector('.slider__overlay-images').classList.remove('is-hidden');
    }
  }

  updateButtons() {
    if (this.prevBttnElement && this.nextBttnElement) {
      if (this.currentImage < 1) {
        this.prevBttnElement.classList.add('is-inactive');
      } else {
        this.prevBttnElement.classList.remove('is-inactive');
      }
      if (this.currentImage >= this.imagesCount - 1) {
        this.nextBttnElement.classList.add('is-inactive');
      } else {
        this.nextBttnElement.classList.remove('is-inactive');
      }
    }
  }
}


// e.g. arbeiten/speidel
class SliderDefault extends Slider {
  moveUlElement() {
    const translateX = `calc(${(this.currentImage * -100)}% + ${this.moveDelta}px)`;
    this.ulElement.style.transitionDuration = '0s';
    this.ulElement.style.transform = `translateX(${translateX})`;
  }

  // slide to currentImage
  slide() {
    const slider = this;
    const { currentImage } = this;
    slider.ulElement.style.transitionDuration = '';
    slider.hideAllOverlayImages(currentImage);
    const translateX = `${(this.currentImage * -100)}%`;
    this.ulElement.style.transform = `translateX(${translateX})`;
    setTimeout(() => {
      // check if currentImage is still this.currentImage (could be change within 400 ms)
      if (currentImage === this.currentImage) {
        this.showOverlayImages(currentImage);
      }
    }, 400);
  }
}


class SliderBackground extends SliderDefault {
}


// images without overflow: hidden
// e.g. http://192.168.0.93:3000/arbeiten/decathlon Bandenwerbung
class SliderSwiper extends Slider {
  constructor(element) {
    super(element);
    const slider = this;
    function onScroll() {
      if (slider.willSoonBeInView) {
        setTimeout(() => {
          slider.loadItem(0);
        }, 100); // make async
        setTimeout(() => {
          slider.loadItem(1);
        }, 200); // make async
        setTimeout(() => {
          slider.loadItem(2);
        }, 300); // make async
      }
    }
    function onResize() {
      slider.slide();
    }
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onResize);
  }

  onMouseUp(event) {
    if (this.isMouseDown) {
      if (this.moveDelta > this.firstFigureElement.offsetWidth) {
        // show second prev image
        this.prevImage();
      }
      if (this.moveDelta < this.firstFigureElement.offsetWidth * -1) {
        // show second next image
        this.nextImage();
      }
    }
    super.onMouseUp(event);
  }

  nextImage() {
    super.nextImage();
    const nextNextImage = this.currentImage + 2;
    setTimeout(() => {
      this.loadItem(nextNextImage);
    }, 100); // make async
  }

  moveUlElement() {
    const translateX = `${(this.currentImage * this.firstLiElement.offsetWidth * -1) + this.moveDelta}px`;
    this.ulElement.style.transitionDuration = '0s';
    this.ulElement.style.transform = `translateX(${translateX})`;
  }

  // slide to currentImage
  slide() {
    const slider = this;
    const { currentImage } = this;
    slider.ulElement.style.transitionDuration = '';
    const currentLiElement = slider.liElements[slider.currentImage];
    const prevLiElement = slider.liElements[slider.currentImage - 1];
    currentLiElement.style.transitionDuration = '';
    if (prevLiElement) {
      prevLiElement.style.transitionDuration = '';
    }
    slider.hideAllOverlayImages(currentImage);
    const translateX = `${(this.currentImage * this.firstLiElement.offsetWidth * -1)}px`;
    this.ulElement.style.transform = `translateX(${translateX})`;
    setTimeout(() => {
      // check if currentImage is still this.currentImage (could be change within 400 ms)
      if (currentImage === this.currentImage) {
        this.showOverlayImages(currentImage);
      }
    }, 400);
  }
}


class SliderLoop extends Slider {
  constructor(element) {
    super(element);

    const slider = this;

    this.loop();

    function onScroll() {
      if (slider.willSoonBeInView) {
        for (let i = 0; i <= slider.imagesCount; i += 1) {
          slider.loadItem(i);
        }
        window.removeEventListener('scroll', onScroll);
        window.removeEventListener('resize', onScroll);
      }
    }
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onScroll);
    setTimeout(onScroll, 400); // wait a few miliseconds to load content
  }

  // change image every 0.6 seconds
  loop() {
    const prevImage = this.currentImage;
    this.liElements[prevImage].style.zIndex = '1';
    if (this.currentImage + 1 <= this.imagesCount - 1) {
      this.currentImage += 1;
    } else {
      this.currentImage = 0;
    }
    this.liElements[this.currentImage].style.opacity = '1';
    this.liElements[this.currentImage].style.zIndex = '2';

    setTimeout(() => {
      this.liElements[prevImage].style.opacity = '0';
    }, 200);
    setTimeout(() => {
      this.loop();
    }, 600);
  }
}


// e.g. arbeiten/schaut
class SliderBook extends Slider {
  constructor(element) {
    super(element);

    // set zIndexes
    [...this.liElements].forEach((liElement, index) => {
      liElement.style.zIndex = `${this.imagesCount - index}`;
    });
  }

  moveUlElement() {
    const { moveDelta } = this;
    const currentLiElement = this.liElements[this.currentImage];
    const prevLiElement = this.liElements[this.currentImage - 1];
    if (moveDelta < 0 && this.liElements[this.currentImage + 1]) { // swipe right
      const figureElement = currentLiElement.querySelector('figure');
      currentLiElement.style.transitionDuration = '0s';
      currentLiElement.style.transform = `translateX(${moveDelta}px)`;
      figureElement.style.transform = `translateX(${moveDelta * -1}px)`;
    } else if (prevLiElement) { // swipe left
      prevLiElement.style.transitionDuration = '0s';
      const figureElement = prevLiElement.querySelector('figure');
      prevLiElement.style.transform = `translateX(calc(-100% + ${moveDelta}px))`;
      figureElement.style.transform = `translateX(calc(100% - ${moveDelta}px))`;
    }
  }

  // slide to currentImage
  slide() {
    const { currentImage } = this;
    [...this.liElements].forEach((liElement, index) => {
      liElement.style.transitionDuration = '';
      if (index < currentImage) {
        const figureElement = liElement.querySelector('figure');
        liElement.style.transform = 'translateX(-100%)';
        figureElement.style.transform = 'translateX(100%)';
      } else {
        const figureElement = liElement.querySelector('figure');
        liElement.style.transform = '';
        figureElement.style.transform = '';
      }
    });
  }
}

const sliderElements = document.querySelectorAll('.slider');
[...sliderElements].forEach((element) => {
  if (element.classList.contains('slider--swiper')) {
    new SliderSwiper(element);
  } else if (element.classList.contains('slider--background')) {
    new SliderBackground(element);
  } else if (element.classList.contains('slider--loop')) {
    new SliderLoop(element);
  } else if (element.classList.contains('slider--book')) {
    new SliderBook(element);
  } else {
    new SliderDefault(element);
  }
});

[...document.querySelectorAll('.slider-text__slider')].forEach(element => new SliderDefault(element));
