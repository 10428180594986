import Component from './Component';
import Picture from './Picture';

class Video extends Component {
  constructor(element) {
    super(element);

    const video = this;

    const iframeElement = element.querySelector('iframe');
    const videoElement = element.querySelector('video');
    const pictureElement = element.querySelector('picture');
    const bttnAudioElement = element.querySelector('.bttn-audio');
    const bttnPlayPauseElement = element.querySelector('.bttn-play-pause');
    let mousemoveTimeout = null;

    const togglePlayPause = () => {
      if (videoElement.paused === true) {
        // pause other videos
        [...document.querySelectorAll('video')].filter((itemElement) => itemElement !== videoElement).forEach((itemElement) => {
          itemElement.pause();
        });
        videoElement.play();
        element.classList.remove('show-button');
      } else {
        videoElement.pause();
      }
    };

    video.iframeElement = iframeElement;
    video.videoElement = videoElement;

    if (pictureElement) {
      video.picture = new Picture(pictureElement);
    }

    function onIFrameLoad() {
      iframeElement.removeEventListener('load', onIFrameLoad);
      element.classList.remove('is-not-loaded');
      setTimeout(() => {
        element.classList.remove('has-preloader');
      }, 400);
    }

    if (iframeElement) {
      iframeElement.addEventListener('load', onIFrameLoad);
    }

    if (videoElement) {
      videoElement.addEventListener('play', () => {
        element.classList.remove('is-not-loaded');
        setTimeout(() => {
          element.classList.remove('has-preloader');
        }, 400);
      });
      videoElement.addEventListener('canplaythrough', () => {
        if (videoElement.paused && video.isPictureLoaded) {
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch(reasons => console.log(reasons)); // DEBUG
          }
        }
      });
      videoElement.addEventListener('play', () => {
        element.classList.toggle('is-playing', true);
      });
      videoElement.addEventListener('pause', () => {
        element.classList.toggle('is-playing', false);
      });
      element.addEventListener('mousemove', () => {
        element.classList.add('show-button');
        clearTimeout(mousemoveTimeout);
        mousemoveTimeout = setTimeout(() => {
          element.classList.remove('show-button');
        }, 1000);
      });
    }

    if (bttnAudioElement && videoElement) {
      bttnAudioElement.addEventListener('click', () => {
        videoElement.muted = !videoElement.muted;
        if (videoElement.muted === false) {
          // mute other videos
          document.querySelectorAll('video').forEach((itemElement) => {
            if (itemElement !== videoElement) {
              itemElement.muted = true;
            }
          });
        }
      });
      videoElement.addEventListener('volumechange', () => {
        bttnAudioElement.classList.toggle('is-loud', !videoElement.muted);
      });
    }

    if (bttnPlayPauseElement && videoElement) {
      videoElement.addEventListener('click', togglePlayPause);
      bttnPlayPauseElement.addEventListener('click', togglePlayPause);
    }

    function onScroll() {
      if (video.willSoonBeInView) {
        video.load();
      }

      if (videoElement) {
        if (video.isInView && videoElement.muted) {
          if (videoElement.paused) {
            const playPromise = videoElement.play();
            if (playPromise !== undefined) {
              playPromise.catch(reasons => console.log(reasons)); // DEBUG
            }
          }
        } else if (!videoElement.paused && videoElement.muted === true) {
          videoElement.pause();
        }
      }
    }
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onScroll);
    onScroll();
  }

  load() {
    const { iframeElement, videoElement, picture } = this;

    // make sure to fire load only once
    if (!this.isLoading) {
      this.isLoading = true;
      if (iframeElement && iframeElement.src !== iframeElement.dataset.src) {
        iframeElement.classList.remove('is-hidden');
        iframeElement.setAttribute('src', iframeElement.dataset.src);
      }
      if (videoElement) {
        const sourceElements = videoElement.querySelectorAll('source');
        [...sourceElements].forEach((sourceElement) => {
          if (!sourceElement.hasAttribute('media')
          || window.matchMedia(sourceElement.getAttribute('media')).matches) {
            sourceElement.setAttribute('src', sourceElement.dataset.src);
            sourceElement.removeAttribute('data-src');
          }
        });
        videoElement.load();
      }
      if (picture) {
        picture.loadImage();
      }
    }
  }
}


[...document.querySelectorAll('.video')].forEach(element => new Video(element));
