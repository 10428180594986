import Component from './Component';

// handels preloading of images

class Picture extends Component {
  constructor(element) {
    super(element);
    this.hasPreloader = false;
    this.isLoading = false;
    if (element.classList.contains('has-preloader')) {
      this.hasPreloader = true;
      if (!this.willSoonBeInView) {
        element.classList.remove('has-preloader');
      }
    }
  }

  // helper method to load picutre elements
  loadImage() {
    const picture = this;
    const { element } = this;
    const imgElement = element.querySelector('img');

    function onLoad() {
      element.classList.remove('is-not-loaded');
      setTimeout(() => {
        element.classList.remove('has-preloader');
        picture.hasPreloader = false;
      }, 300);
    }

    if (!this.isLoading && picture.hasPreloader === true) {
      this.isLoading = true;
      element.classList.add('has-preloader'); // show loading indicator
      imgElement.addEventListener('load', onLoad);
      const sourceElements = element.querySelectorAll('source');
      if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident') > 0) {
        onLoad();
        const lastSourceElement = [...sourceElements][sourceElements.length - 1];
        let src = '';
        if (lastSourceElement.dataset.srcset) {
          src = lastSourceElement.dataset.srcset;
        } else if (lastSourceElement.getAttribute('src')) {
          src = lastSourceElement.getAttribute('src');
        } else if (lastSourceElement.getAttribute('srcset')) {
          src = lastSourceElement.getAttribute('srcset');
        }
        src = src.split(', ');
        src = src[src.length - 1];
        if (src.indexOf(' 2x') !== -1) {
          src = src.substring(0, src.indexOf(' 2x'));
        }
        if (src.length > 0) {
          imgElement.setAttribute('src', src);
        }
      }
      [...sourceElements].forEach((sourceElement) => {
        if (sourceElement.hasAttribute('data-srcset')) {
          sourceElement.setAttribute('srcset', sourceElement.dataset.srcset);
          sourceElement.removeAttribute('data-srcset');
        }
      });
      if (imgElement.complete) {
        onLoad();
      }
    }
  }
}

export default Picture;
