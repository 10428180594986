class Mainnav {
  constructor(element) {
    const mainnav = this;
    const headerElement = document.querySelector('.header');
    const bttnHamburgerElement = document.querySelector('.bttn-hamburger');

    this.element = element;
    this.headerElement = headerElement;
    this.bttnHamburgerElement = bttnHamburgerElement;

    function toggleNav() {
      if (!element.classList.contains('is-visible')) {
        mainnav.open();
      } else {
        mainnav.close();
      }
    }

    if (window.matchMedia(`(max-width: ${(960 / 16)}em)`).matches) {
      mainnav.close();
    }

    bttnHamburgerElement.addEventListener('click', toggleNav);
  }

  open() {
    const { element, headerElement, bttnHamburgerElement } = this;
    element.hidden = false;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        element.classList.add('is-visible');
        bttnHamburgerElement.setAttribute('aria-expanded', 'true');
        document.scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
        document.querySelector('html').classList.add('no-scroll');
        setTimeout(() => {
          if (element.classList.contains('is-visible')) {
            headerElement.classList.add('is-fixed');
          }
        }, 400);
      });
    });
  }

  close() {
    const { element, headerElement, bttnHamburgerElement } = this;
    function onTransitionend() {
      if (!element.classList.contains('is-visible')) {
        element.removeEventListener('transitionend', onTransitionend);
        element.hidden = true;
      }
    }

    element.removeEventListener('transitionend', onTransitionend);
    element.addEventListener('transitionend', onTransitionend);
    element.classList.remove('is-visible');
    headerElement.classList.remove('is-fixed');
    bttnHamburgerElement.setAttribute('aria-expanded', 'false');
    document.querySelector('html').classList.remove('no-scroll');
  }
}


[...document.querySelectorAll('.mainnav')].forEach(element => new Mainnav(element));
