import Component from './Component';

// video elements inside .images
// e.g. arbeiten/schaut
class ImagesVideo extends Component {
  constructor(element) {
    super(element);

    const imagesVideo = this;

    this.isLoading = false;

    function onScroll() {
      if (!imagesVideo.isLoading && imagesVideo.willSoonBeInView) {
        imagesVideo.load();
      }

      if (imagesVideo.isInView) {
        if (element.paused) {
          const playPromise = element.play();
          if (playPromise !== undefined) {
            playPromise.catch(reasons => console.log(reasons)); // DEBUG
          }
        }
      } else {
        element.pause();
      }
    }
    window.addEventListener('resize', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();
  }

  load() {
    const { element } = this;
    if (!this.isLoading) {
      this.isLoading = true;
      if (element.dataset.poster && element.poster !== element.dataset.poster) {
        element.poster = element.dataset.poster;
      }
      [...element.querySelectorAll('source')].forEach((sourceElement) => {
        if (!sourceElement.hasAttribute('media')
        || window.matchMedia(sourceElement.getAttribute('media')).matches) {
          sourceElement.setAttribute('src', sourceElement.dataset.src);
          sourceElement.removeAttribute('data-src');
        }
      });
      element.load();
    }
  }
}


[...document.querySelectorAll('.images video')].forEach(element => new ImagesVideo(element));
