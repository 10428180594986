import Component from './Component';

class Browser extends Component {
  constructor(element, browserContainer) {
    super(element);

    const browser = this;
    const videoElement = element.querySelector('video');
    const iFrameElement = this.element.querySelector('iframe');
    const pictureElement = this.element.querySelector('picture');
    const barElement = element.querySelector('.browser__bar');
    const contentElement = element.querySelector('.browser__content');
    let containerElement = browserContainer.element;
    if (browserContainer.element.parentElement.classList.contains('has-color-bg')
    || browserContainer.element.parentElement.classList.contains('has-lightgray-bg')) {
      containerElement = browserContainer.element.parentElement;
    }
    let x = 0; // vertical movement in px
    let y = 0; // horizontal movement in px
    let isMouseDown = false;
    let mouseXStart = 0;
    let mouseYStart = 0;

    this.videoElement = videoElement;
    this.iFrameElement = iFrameElement;
    this.pictureElement = pictureElement;
    this.contentElement = contentElement;

    function draw() {
      // don’t draw if browser is part of tabs view (eg Speidel “Websites und Webshops”)
      if (!browserContainer.hasTabs) {
        element.style.transform = `translate(${x}px, ${y}px)`;
        if (isMouseDown) {
          requestAnimationFrame(draw);
        }
      }
    }

    function collisionControl() {
      if (x + element.offsetLeft <= 0) {
        x = element.offsetLeft * -1;
      }
      if ((x + element.offsetLeft + element.offsetWidth) >= containerElement.offsetWidth) {
        x = containerElement.offsetWidth - (element.offsetLeft + element.offsetWidth);
      }
      if (y + element.offsetTop <= 0) {
        y = element.offsetTop * -1;
      }
      if ((y + element.offsetTop + element.offsetHeight) >= containerElement.offsetHeight) {
        y = containerElement.offsetHeight - (element.offsetTop + element.offsetHeight);
      }
    }

    function onMousedown(event) {
      browserContainer.makeAllBrowsersInactive(browser);
      if (!document.body.classList.contains('is-touch')) {
        element.classList.add('is-active');
        if (event.target === barElement) {
          element.classList.remove('is-content-active');
          isMouseDown = true;
          mouseXStart = event.clientX - x;
          mouseYStart = event.clientY - y;
          document.body.classList.add('prevent-selection');
          element.style.willChange = 'transform';
          draw();
        } else if (videoElement) {
          videoElement.controls = true;
        }
      }
    }
    function onMousemove(event) {
      if (isMouseDown) {
        event.preventDefault();
        event.stopPropagation();
        x = event.clientX - mouseXStart;
        y = event.clientY - mouseYStart;
        collisionControl();
      }
    }
    function onMouseup(event) {
      if (event.target.closest('.browser') === element
      && !document.body.classList.contains('is-touch')) {
        element.classList.add('is-content-active');
      }
      isMouseDown = false;
      mouseXStart = 0;
      mouseYStart = 0;
      document.body.classList.remove('prevent-selection');
      element.style.willChange = '';
    }
    function onResize() {
      collisionControl();
      draw();
    }
    function onScroll() {
      if (browserContainer.willSoonBeInView) {
        if (browser.willSoonBeInView) {
          browser.load();
        }
        if (videoElement) {
          if (browser.isInView) {
            if (videoElement.paused) {
              const playPromise = videoElement.play();
              if (playPromise !== undefined) {
                playPromise.catch(reasons => console.log(reasons)); // DEBUG
              }
            }
          } else {
            videoElement.pause();
          }
        }
      }
    }

    element.addEventListener('mousedown', onMousedown);
    window.addEventListener('mousemove', onMousemove);
    window.addEventListener('mouseup', onMouseup);
    window.addEventListener('resize', onResize);
    document.addEventListener('scroll', onScroll, { passive: true });
    setTimeout(onScroll, 600); // wait a few miliseconds to load content
  }

  makeInactive() {
    this.element.classList.remove('is-active');
    this.element.classList.remove('is-content-active');
    if (this.videoElement) {
      this.videoElement.controls = false;
    }
  }

  load() {
    const {
      element, videoElement, iFrameElement, pictureElement,
    } = this;

    function onLoad() {
      element.classList.remove('is-not-loaded');
      setTimeout(() => {
        element.classList.remove('has-preloader');
      }, 300);
    }

    // make sure to fire load only once
    if (!this.isLoading) {
      this.isLoading = true;
      if (iFrameElement && iFrameElement.src !== iFrameElement.dataset.src) {
        iFrameElement.addEventListener('load', onLoad);
        iFrameElement.src = iFrameElement.dataset.src;
      }
      if (pictureElement) {
        const imgElement = pictureElement.querySelector('img');
        imgElement.addEventListener('load', onLoad);
        const sourceElements = element.querySelectorAll('source');

        if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident') > 0) {
          onLoad();
          const lastSourceElement = [...sourceElements][sourceElements.length - 1];
          let src;
          if (lastSourceElement.dataset.srcset) {
            src = lastSourceElement.dataset.srcset;
          } else if (lastSourceElement.getAttribute('src')) {
            src = lastSourceElement.getAttribute('src');
          }
          src = src.split(', ');
          src = src[src.length - 1];
          if (src.indexOf(' 2x') !== -1) {
            src = src.substring(0, src.indexOf(' 2x'));
          }
          if (src.length > 0) {
            imgElement.setAttribute('src', src);
          }
        }
        [...sourceElements].forEach((sourceElement) => {
          if (sourceElement.hasAttribute('data-srcset')) {
            sourceElement.setAttribute('srcset', sourceElement.dataset.srcset);
            sourceElement.removeAttribute('data-srcset');
          }
        });
      }
      if (videoElement) {
        const sourceElements = this.videoElement.querySelectorAll('source');
        videoElement.addEventListener('play', onLoad);
        [...sourceElements].forEach((sourceElement) => {
          if (!sourceElement.hasAttribute('media')
          || window.matchMedia(sourceElement.getAttribute('media')).matches) {
            sourceElement.setAttribute('src', sourceElement.dataset.src);
            sourceElement.removeAttribute('data-src');
          }
        });
        this.videoElement.load();
      }
    }
  }
}

export default Browser;
