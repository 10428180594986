import Component from './Component';

class HomeVideo extends Component {
  constructor(element) {
    super(element);

    const homeVideo = this;
    const h2Element = element.querySelector('h2');
    const bttnDownElement = element.querySelector('.bttn-down');
    const mainnavElement = document.querySelector('.mainnav');
    const videoElement = document.querySelector('video');

    this.videoElement = videoElement;

    setTimeout(() => {
      h2Element.classList.remove('is-hidden');
    }, 1000);

    setTimeout(() => {
      bttnDownElement.classList.remove('is-hidden');
    }, 3000);

    function draw() {
      if (homeVideo.willSoonBeInView) {
        const translateY = document.scrollingElement.scrollTop * 0.2;
        bttnDownElement.style.transform = `translateY(${translateY * 2}px) translateZ(1px)`;
      }
      requestAnimationFrame(draw);
    }
    draw();

    this.loadVideo();


    bttnDownElement.addEventListener('click', () => {
      const top = element.nextElementSibling.offsetTop - mainnavElement.offsetHeight;
      window.scroll({ top, behavior: 'smooth' });
    });
    // window.addEventListener('scroll', () => {
    //   if (homeVideo.isInView) {
    //     if (videoElement.paused) {
    //       const playPromise = videoElement.play();
    //       if (playPromise !== undefined) {
    //         playPromise.catch(reasons => console.log(reasons)); // DEBUG
    //       }
    //     }
    //   } else if (videoElement.paused) {
    //     videoElement.pause();
    //   }
    // }, { passive: true });
  }

  loadVideo() {
    const { videoElement } = this;
    videoElement.setAttribute('autoplay', 'true');
    if (videoElement.dataset.poster) {
      videoElement.setAttribute('poster', videoElement.dataset.poster);
    }
    super.loadVideo();
  }
}

[...document.querySelectorAll('.home-video')].forEach(element => new HomeVideo(element));
