import Picture from './Picture';

class WorkThumbPicture extends Picture {
  constructor(element) {
    super(element);

    const workThumbPicture = this;

    function onScroll() {
      if (workThumbPicture.willSoonBeInView) {
        window.removeEventListener('scroll', onScroll);
        window.removeEventListener('resize', onScroll);
        workThumbPicture.loadImage();
      }
    }

    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onScroll);
    onScroll();
  }
}


[...document.querySelectorAll('.work-thumb picture')].forEach(element => new WorkThumbPicture(element));
