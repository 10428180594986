const element = document.querySelector('.map-weather');
if (element) {
  const url = 'https://api.openweathermap.org/data/2.5/weather?id=2847736&units=metric&appid=ebe705bff5ef407153fd2de413c77aa2&lang=de';
  fetch(url).then(response => response.json()).then((data) => {
    // const hotness = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 1 }).format(data.main.temp);
    const hotness = Math.round(data.main.temp);
    element.innerHTML = `<div><img src="/assets/images/weather-icons/${data.weather[0].id}.svg" alt=""> ${hotness} °C, ${data.weather[0].description}</div>`;
    element.classList.remove('is-hidden');
  });
}
