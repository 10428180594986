import Component from './Component';

class Hero extends Component {
  constructor(element) {
    super(element);

    const hero = this;
    const loadingIndicatorElement = element.querySelector('.loading-indicator');
    const bttnDownElement = element.querySelector('.bttn-down');
    const h2Element = element.querySelector('h2');
    const pictureElement = element.querySelector('picture');
    const imgElement = pictureElement ? pictureElement.querySelector('picture img') : null;
    const nextElement = element.nextElementSibling;
    const mainnavElement = document.querySelector('.mainnav');
    let isDrawing = false;
    let animationFrame;

    function draw() {
      isDrawing = true;
      const translateY = document.scrollingElement.scrollTop * 0.2;
      h2Element.style.transform = `translateY(${translateY}px)`;
      pictureElement.style.transform = `translateY(${translateY * 0.5}px)`;
      bttnDownElement.style.transform = `translateY(${translateY * 2}px)`;
      animationFrame = requestAnimationFrame(draw);
    }
    isDrawing = true;
    animationFrame = requestAnimationFrame(draw);

    function showH2() {
      h2Element.classList.remove('is-hidden');
    }
    function showImage() {
      pictureElement.classList.remove('is-hidden');
      setTimeout(showH2, 300);
    }

    function onBttnDownClick() {
      const top = nextElement.offsetTop - mainnavElement.offsetHeight;
      window.scroll({ top, behavior: 'smooth' });
    }
    if (imgElement) {
      loadingIndicatorElement.classList.add('is-hidden');
      bttnDownElement.classList.remove('is-hidden');
      showImage();
      setTimeout(() => {
        loadingIndicatorElement.style.display = 'none';
      });
    }
    if (bttnDownElement) {
      bttnDownElement.addEventListener('click', onBttnDownClick);
    }

    function onScroll() {
      if (hero.willSoonBeInView) {
        h2Element.style.willChange = 'transform';
        pictureElement.style.willChange = 'transform';
        bttnDownElement.style.willChange = 'transform';
        if (!isDrawing) {
          isDrawing = true;
          animationFrame = requestAnimationFrame(draw);
        }
      } else {
        h2Element.style.willChange = '';
        pictureElement.style.willChange = '';
        bttnDownElement.style.willChange = '';
        cancelAnimationFrame(animationFrame);
        isDrawing = false;
      }
    }
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onScroll);
    onScroll();
  }
}


[...document.querySelectorAll('.hero')].forEach(element => new Hero(element));
