import Browser from './Browser';
import Component from './Component';

class BrowserContainer extends Component {
  constructor(element) {
    super(element);

    const browserContainer = this;
    const browserElements = element.querySelectorAll('.browser');

    this.hasTabs = element.classList.contains('has-tabs');

    browserContainer.browsers = [];
    [...browserElements].forEach((browserElement) => {
      browserContainer.browsers.push(new Browser(browserElement, browserContainer));
    });

    function onClick(event) {
      if (!event.target.closest('.browser')) {
        browserContainer.makeAllBrowsersInactive();
      }
    }
    document.addEventListener('click', onClick);

    const scrollContainerElement = element.querySelector('.browser-container__scroll-container');
    const tabsButtonElements = element.querySelectorAll('.browser-container__tabs button');
    function handleTabsButtonClick() {
      const { i } = this.dataset;
      [...tabsButtonElements].forEach((tabsButtonElement) => {
        tabsButtonElement.classList.remove('is-active');
      });
      this.classList.add('is-active');
      scrollContainerElement.style.transform = `translateX(${i * -100}%)`;
    }
    if (this.hasTabs && scrollContainerElement && tabsButtonElements) {
      [...tabsButtonElements].forEach((tabsButtonElement) => {
        tabsButtonElement.addEventListener('mouseover', handleTabsButtonClick);
      });
    }

    function onScroll() {
      if (browserContainer.willSoonBeInView) {
        element.style.visibility = '';
      } else {
        element.style.visibility = 'hidden';
      }
    }
    document.addEventListener('scroll', onScroll, { passive: true });
  }

  makeAllBrowsersInactive(except) {
    console.log('make all browsers inactive');
    this.browsers.forEach((browser) => {
      if (browser !== except) {
        browser.makeInactive();
      }
    });
  }
}


window.browserContainers = [];
[...document.querySelectorAll('.browser-container')].forEach((element) => {
  window.browserContainers.push(new BrowserContainer(element));
});
