import Component from './Component';
import Picture from './Picture';

class NewsThumb extends Component {
  constructor(element) {
    super(element);

    const newsThumb = this;
    const picture = new Picture(element.querySelector('picture'));

    function checkIfContentShouldLoad() {
      if (newsThumb.willSoonBeInView) {
        document.removeEventListener('scroll', checkIfContentShouldLoad);
        document.removeEventListener('resize', checkIfContentShouldLoad);
        picture.loadImage();
      }
    }

    document.addEventListener('scroll', checkIfContentShouldLoad, { passive: true });
    document.addEventListener('resize', checkIfContentShouldLoad);
    checkIfContentShouldLoad();
  }
}

[...document.querySelectorAll('.news-thumb')].forEach(element => new NewsThumb(element));
