import Component from './Component';

class WorksCarousel extends Component {
  constructor(element) {
    super(element);
    const worksCarousel = this;
    const ulElement = element.querySelector('ul');
    const workThumbElements = element.querySelectorAll('.work-thumb');
    const bttnPrevElement = element.querySelector('.bttn-prev');
    const bttnNextElement = element.querySelector('.bttn-next');
    let iOfActiveElement = 0;

    this.currentI = 0;
    this.isMouseDown = false;
    this.mouseXStart = false;
    this.mouseDeltaX = false;
    this.element = element;
    this.ulElement = ulElement;
    this.workThumbElements = workThumbElements;
    this.bttnPrevElement = bttnPrevElement;
    this.bttnNextElement = bttnNextElement;

    window.addEventListener('load', () => {
      const activeElement = ulElement.querySelector('.work-thumb.is-active');
      if (!window.matchMedia(`(max-width: ${960 / 16}em)`).matches) {
        while (activeElement !== workThumbElements[iOfActiveElement]) {
          iOfActiveElement += 1;
        }
        this.currentI = iOfActiveElement + 1;
        this.moveUl();
      } else if (activeElement.nextElementSibling) {
        this.ulElement.scrollLeft = activeElement.nextElementSibling.offsetLeft;
      }
    });

    function onMouseDown(event) {
      if (event.type !== 'mousedown' || (event.which === 1 && !event.ctrlKey && !event.metaKey && !event.altKey)) {
        worksCarousel.isMouseDown = true;
        worksCarousel.mouseXStart = event.pageX;
        ulElement.style.transitionDuration = '0s';
      }
    }
    function onMouseMove(event) {
      if (worksCarousel.isMouseDown) {
        event.preventDefault();
        worksCarousel.mouseDeltaX = (event.pageX - worksCarousel.mouseXStart) * -1;
        worksCarousel.moveUl();
      }
    }
    function onMouseUp() {
      const { mouseDeltaX } = worksCarousel;
      worksCarousel.isMouseDown = false;
      worksCarousel.mouseXStart = 0;
      worksCarousel.mouseDeltaX = 0;
      ulElement.style.transitionDuration = '';
      const workThumbWidth = worksCarousel.workThumbElements[0].offsetWidth;
      if (mouseDeltaX > workThumbWidth * 0.25) {
        worksCarousel.showNext();
      } else if (mouseDeltaX * -1 > workThumbWidth * 0.25) {
        worksCarousel.showPrev();
      } else {
        worksCarousel.moveUl();
      }
    }
    function onKeydown(event) {
      if (worksCarousel.isInView) {
        if (event.code === 'ArrowRight') {
          worksCarousel.showNext();
        } else if (event.code === 'ArrowLeft') {
          worksCarousel.showPrev();
        }
      }
    }
    element.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('keydown', onKeydown);
    bttnNextElement.addEventListener('click', () => worksCarousel.showNext());
    bttnPrevElement.addEventListener('click', () => worksCarousel.showPrev());
    window.addEventListener('resize', () => worksCarousel.moveUl());

    this.updateButton();
    this.updateWorkThumbs();
  }

  showPrev() {
    this.currentI -= 2;
    this.moveUl();
  }

  showNext() {
    this.currentI += 2;
    this.moveUl();
  }

  moveUl() {
    if (!window.matchMedia(`(max-width: ${960 / 16}em)`).matches) {
      let { mouseDeltaX } = this;
      if (this.currentI <= 0) {
        this.currentI = 0;
        mouseDeltaX = Math.sqrt(mouseDeltaX * -1) * -2;
      }
      if (this.currentI >= this.workThumbElements.length - 2) {
        this.currentI = this.workThumbElements.length - 2;
        mouseDeltaX = Math.sqrt(mouseDeltaX) * 2;
      }
      const workThumbWidth = this.workThumbElements[0].offsetWidth;
      const ulPosition = workThumbWidth * this.currentI;
      this.ulElement.style.transform = `translateX(${(ulPosition + mouseDeltaX) * -1}px)`;
      this.updateButton();
      this.updateWorkThumbs();
    } else {
      this.ulElement.style.transform = '';
    }
  }

  updateButton() {
    if (this.currentI <= 0) {
      this.bttnPrevElement.classList.add('is-inactive');
    } else {
      this.bttnPrevElement.classList.remove('is-inactive');
    }
    if (this.currentI >= this.workThumbElements.length - 2) {
      this.bttnNextElement.classList.add('is-inactive');
    } else {
      this.bttnNextElement.classList.remove('is-inactive');
    }
  }

  updateWorkThumbs() {
    [...this.workThumbElements].forEach((element, nth) => {
      if (this.currentI === nth || this.currentI + 1 === nth) {
        element.classList.remove('is-inactive');
      } else {
        element.classList.add('is-inactive');
      }
    });
  }
}


[...document.querySelectorAll('.works-carousel')].forEach(element => new WorksCarousel(element));
