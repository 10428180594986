import Component from './Component';
import Picture from './Picture';

class Error extends Component {
  constructor(element) {
    const figureElement = element.querySelector('figure');
    const videoElement = element.querySelector('video');
    const pictureElement = element.querySelector('picture');
    const bttnElement = element.querySelector('.bttn-rounded');
    const picture = new Picture(pictureElement);

    super(element);

    const error = this;

    this.figureElement = figureElement;
    this.videoElement = videoElement;
    this.picture = picture;
    this.load();


    function toggleVideo() {
      if (videoElement.muted) {
        videoElement.muted = false;
        videoElement.controls = true;
        element.classList.add('show-video-only');
      } else {
        videoElement.muted = true;
        videoElement.controls = false;
        element.classList.remove('show-video-only');
        if (videoElement.paused) {
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch(exception => console.log(exception)); // DEBUG
          }
        }
      }
    }

    videoElement.addEventListener('play', () => {
      error.removePreloader();
      pictureElement.style.opacity = '0';
      if (typeof videoElement.currentSrc !== 'string' || videoElement.currentSrc.length === 0) {
        picture.loadImage();
        picture.element.style.opacity = '1';
      }
    });

    bttnElement.addEventListener('click', () => {
      toggleVideo();
    });
  }

  removePreloader() {
    const { figureElement } = this;
    figureElement.classList.remove('is-not-loaded');
    setTimeout(() => {
      figureElement.classList.remove('has-preloader');
    }, 400);
  }

  load() {
    const error = this;
    const { videoElement, picture } = this;
    const sourceElements = videoElement.querySelectorAll('source');

    [...sourceElements].forEach((sourceElement) => {
      if (!sourceElement.hasAttribute('media')
      || window.matchMedia(sourceElement.getAttribute('media')).matches) {
        sourceElement.setAttribute('src', sourceElement.dataset.src);
        sourceElement.removeAttribute('data-src');
      }
    });
    videoElement.load();

    const playPromise = videoElement.play();
    if (playPromise !== undefined) {
      playPromise.catch((exception) => {
        picture.loadImage();
        picture.element.style.opacity = '1';
        error.removePreloader();
        console.log(videoElement.currentSrc);
        console.log(exception);
      });
    }
  }
}

const errorElement = document.querySelector('.m-error');
if (errorElement) {
  new Error(errorElement);
}
