/*

*/

class ClientListItem {
  constructor(element) {
    const clientListItem = this;
    const h3Element = element.querySelector('h3');
    const buttonElement = element.querySelector('button');
    const ulElement = element.querySelector('ul');

    this.element = element;
    this.h3Element = h3Element;
    this.buttonElement = buttonElement;
    this.ulElement = ulElement;

    if (buttonElement) {
      buttonElement.addEventListener('click', () => {
        if (ulElement.hidden) {
          clientListItem.open();
        } else {
          clientListItem.close();
        }
      });
    }
  }

  open() {
    const { element, ulElement, buttonElement } = this;

    function getHeight() {
      ulElement.style.position = 'absolute';
      ulElement.style.left = '0';
      ulElement.style.right = '0';
      const height = ulElement.offsetHeight + ulElement.offsetTop;
      ulElement.style.position = '';
      ulElement.style.left = '';
      ulElement.style.right = '';
      return height;
    }

    function onTransitionend(event) {
      if (event.target === element) {
        element.removeEventListener('transitionend', onTransitionend);
        element.style.height = '';
      }
    }

    element.style.height = `${element.offsetHeight}px`;
    ulElement.hidden = false;
    ulElement.classList.add('is-hidden');
    buttonElement.setAttribute('aria-expanded', 'true');
    const height = getHeight();
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        element.style.height = `${height}px`;
        ulElement.classList.remove('is-hidden');
        element.removeEventListener('transitionend', onTransitionend);
        element.addEventListener('transitionend', onTransitionend);
      });
    });
  }

  close() {
    const {
      element, h3Element, ulElement, buttonElement,
    } = this;

    function onTransitionend(event) {
      if (event.target === element) {
        element.removeEventListener('transitionend', onTransitionend);
        ulElement.hidden = true;
        element.style.height = '';
      }
    }

    ulElement.classList.add('is-hidden');
    buttonElement.setAttribute('aria-expanded', 'false');
    element.style.height = `${element.offsetHeight}px`;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setTimeout(() => {
          element.style.height = `${h3Element.offsetHeight}px`;
          element.removeEventListener('transitionend', onTransitionend);
          element.addEventListener('transitionend', onTransitionend);
        }, 100);
      });
    });
  }
}

[...document.querySelectorAll('.client-list__item')].forEach((element) => {
  new ClientListItem(element);
});
