import File from './File';

class ApplicationForm {
  constructor(element) {
    const applicationForm = this;
    const overlayElement = element.querySelector('.form__overlay');
    const successElement = element.querySelector('.form__success');
    const inputElements = element.querySelectorAll('input, textarea');
    const errorElement = element.querySelector('p.error');
    const filesInputElement = element.querySelector('.files-input');
    const filesInputUlElement = filesInputElement.querySelector('ul');
    const inputFilesElement = element.querySelector('input[name="files"]');
    const inputFileElement = element.querySelector('input[type="file"]');
    const headers = new Headers(); // FOR DEBUG
    headers.append('Authorization', `Basic ${btoa('vorschau:apfelbaum')}`); // FOR DEBUG


    this.files = [];
    this.filesInputElement = filesInputElement;
    this.filesInputUlElement = filesInputUlElement;
    this.inputFilesElement = inputFilesElement;
    this.overlayElement = overlayElement;

    function hasFilesErrors() {
      const { files } = applicationForm;
      return files.filter(file => file.error.length > 0).length > 0;
    }

    function confirmServer(pageID) {
      const url = '/api/application-form/confirm';
      const formData = new FormData();
      formData.append('pageID', pageID);
      fetch(url, {
        headers,
        method: 'POST',
        body: formData,
      });
    }

    function refuseServer(pageID) {
      const url = '/api/application-form/refuse';
      const formData = new FormData();
      formData.append('pageID', pageID);
      fetch(url, {
        headers,
        method: 'POST',
        body: formData,
      });
    }

    function showSuccessMessage() {
      successElement.style.display = 'block';
      element.style.height = `${element.offsetHeight}px`;
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          successElement.classList.add('is-visible');
          const successElementTop = successElement.getBoundingClientRect().top;
          const top = (successElementTop + document.scrollingElement.scrollTop) - 80;
          element.style.height = `${successElement.offsetHeight}px`;
          window.scroll({ top, left: 0, behavior: 'smooth' });
        });
      });
    }

    function showOverlay() {
      overlayElement.style.display = 'flex';
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          overlayElement.classList.add('is-visible');
        });
      });
    }

    function updateOverlay(text) {
      const spanElement = overlayElement.querySelector('span');
      spanElement.innerHTML = text;
    }

    function updateOverlayWithFiles(filesToUpload) {
      let innerHTML = '';
      filesToUpload.forEach((file) => {
        innerHTML += `${file.file.name} (${file.stringSizeInMB} MB) wird hochgeladen.<br>`;
      });
      updateOverlay(innerHTML);
    }

    function hideOverlay() {
      overlayElement.classList.remove('is-visible');
      setTimeout(() => {
        overlayElement.style.display = '';
      }, 500);
    }

    function resetInputElements() {
      [...inputElements].forEach((inputElement) => {
        inputElement.value = '';
      });
    }

    function makeFormInactive() {
      [...inputElements].forEach((inputElement) => {
        inputElement.readOnly = true;
      });
    }

    function makeFormActive() {
      console.log('make form active');
      [...inputElements].forEach((inputElement) => {
        inputElement.readOnly = false;
      });
    }

    function removeAllErrorMessages() {
      const inputErrorElements = element.querySelectorAll('span.error');
      [...inputErrorElements].forEach((inputErrorElement) => {
        inputErrorElement.parentElement.removeChild(inputErrorElement);
      });
      errorElement.innerText = '';
    }

    function createErrorElement(text) {
      const inputErrorElement = document.createElement('span');
      inputErrorElement.classList.add('error');
      inputErrorElement.innerText = text;
      return inputErrorElement;
    }

    function handleErrors(errors) {
      let firstInputElementWithError;
      errors.forEach((error) => {
        const inputElementWithError = [...inputElements].find(inputElement => inputElement.name === error.fieldName);
        if (inputElementWithError && inputElementWithError.parentElement) {
          inputElementWithError.parentElement.appendChild(createErrorElement(error.text));
          if (typeof firstInputElementWithError === 'undefined') {
            firstInputElementWithError = inputElementWithError;
          }
        } else {
          errorElement.innerText = error;
        }
      });
      try {
        const labelElement = firstInputElementWithError.parentElement;
        const errorElementTop = labelElement.getBoundingClientRect().top;
        const top = (errorElementTop + document.scrollingElement.scrollTop) - 80;
        window.scroll({ top, left: 0, behavior: 'smooth' });
        setTimeout(() => {
          firstInputElementWithError.focus();
        }, 100);
      } catch (exception) {
        console.log(exception);
      }
    }

    function addFiles(newFiles) {
      [...newFiles].forEach((file) => {
        const newFile = new File(file, applicationForm);
        applicationForm.files.push(newFile);
      });
    }

    function uploadFiles(pageID) {
      return new Promise((resolve, reject) => {
        const filesToUpload = applicationForm.files.slice();
        updateOverlayWithFiles(filesToUpload);
        applicationForm.files.forEach((file) => {
          file.upload(pageID).then((uploadedFile) => {
            const removePosition = filesToUpload.indexOf(uploadedFile);
            if (removePosition !== -1) {
              filesToUpload.splice(removePosition, 1);
            }
            updateOverlayWithFiles(filesToUpload);
            if (filesToUpload.length === 0) {
              console.log('All Files uploaded.');
              resolve();
            }
          }).catch((error) => {
            reject(error);
          });
        });
      });
    }

    // add file via file selector
    inputFileElement.addEventListener('change', () => {
      addFiles(inputFileElement.files);
    });

    filesInputElement.addEventListener('drop', (event) => {
      addFiles(event.dataTransfer.files);
    }, false);

    function highlightFilesInputElement() {
      filesInputElement.classList.add('is-highlighted');
    }
    ['dragenter', 'dragover'].forEach((eventName) => {
      filesInputElement.addEventListener(eventName, highlightFilesInputElement, false);
    });

    function unhighlightFilesInputElement() {
      filesInputElement.classList.remove('is-highlighted');
    }
    ['dragleave', 'drop'].forEach((eventName) => {
      filesInputElement.addEventListener(eventName, unhighlightFilesInputElement, false);
    });

    function preventDefaults(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      filesInputElement.addEventListener(eventName, preventDefaults, false);
    });

    function sendData() {
      const url = '/api/application-form/submit';
      updateOverlay('Formular-Daten werden hochgeladen');
      fetch(url, {
        headers,
        method: 'POST',
        body: new FormData(element),
      }).then((response) => {
        if (response.status !== 200 || response.status !== 201) {
          errorElement.innerText = `Server Error: ${response.url} ${response.statusText} (${response.status})`;
        }
        return response.json();
      }).then((data) => {
        removeAllErrorMessages();
        try {
          if (data.errors) {
            hideOverlay();
            makeFormActive();
            handleErrors(data.errors);
          } else if (data.pageID) {
            uploadFiles(data.pageID).then(() => {
              confirmServer(data.pageID);
              resetInputElements();
              hideOverlay();
              showSuccessMessage();
            }).catch((error) => {
              refuseServer(data.pageID);
              errorElement.innerText = error.message;
              hideOverlay();
              makeFormActive();
            });
          }
        } catch (error) {
          errorElement.innerText = error.message;
          console.error(error);
        }
      }).catch((error) => {
        hideOverlay();
        makeFormActive();
        errorElement.innerText = error.message;
        console.error(error);
      });
    }

    function onSubmit(event) {
      event.preventDefault();
      errorElement.innerText = '';
      if (applicationForm.files.length > 5) {
        removeAllErrorMessages();
        errorElement.innerText = 'Es dürfen maximal fünf Dateien hochgeladen werden.';
      } else if (applicationForm.files.length <= 0) {
        removeAllErrorMessages();
        errorElement.innerText = 'Bitte füge deiner Bewerbung mindestens eine Datei hinzu.';
      } else if (hasFilesErrors()) {
        removeAllErrorMessages();
        const { files } = applicationForm;
        const fileWithError = files.filter(file => file.error.length > 0)[0];
        errorElement.innerText = `Die Datei ${fileWithError.file.name} kann nicht hochgeladen werden.`;
      } else {
        showOverlay();
        makeFormInactive();
        setTimeout(sendData, 400);
      }
    }
    element.addEventListener('submit', onSubmit);
  }
}

[...document.querySelectorAll('.application-form')].forEach(element => window.application = new ApplicationForm(element));
