// imported by ApplicationForm

class File {
  constructor(_file, applicationForm) {
    const file = this;
    const element = document.createElement('li');
    const buttonDeleteElement = document.createElement('span');
    const errorElement = document.createElement('p');
    const sizeInMB = _file.size / 1000000;
    const stringSizeInMB = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 1, useGrouping: true }).format(sizeInMB);

    this.file = _file;
    this.error = '';
    this.stringSizeInMB = stringSizeInMB;
    this.applicationForm = applicationForm;
    this.element = element;
    this.errorElement = errorElement;

    buttonDeleteElement.title = 'Datei entfernen';
    buttonDeleteElement.type = 'button';

    element.addEventListener('click', (event) => {
      event.preventDefault();
    });

    buttonDeleteElement.addEventListener('click', (event) => {
      event.preventDefault();
      file.remove();
    });

    element.innerHTML = `<strong>${_file.name}</strong> <em>${stringSizeInMB} MB</em><div class="file__success"></div>`;
    if (_file.size > 15 * 1000 * 1000) { // 15 MB
      this.error = 'Maximale Dateigröße überschritten';
      errorElement.innerText = this.error;
    }
    element.classList.add('file');
    element.appendChild(buttonDeleteElement);
    element.appendChild(errorElement);
    applicationForm.filesInputUlElement.appendChild(element);
  }

  remove() {
    const { applicationForm, element } = this;
    element.parentElement.removeChild(element);

    // remove item from files array
    applicationForm.files.splice(applicationForm.files.findIndex((item => item === this)), 1);
  }

  upload(pageID) {
    const file = this;
    const { errorElement } = this;
    const url = '/api/application-form/upload';
    const formData = new FormData();

    return new Promise((resolve, reject) => {
      formData.append('pageID', pageID);
      formData.append('file', this.file);
      errorElement.innerText = '';

      fetch(url, {
        method: 'POST',
        body: formData,
      }).then((response) => {
        if (response.status === 201) {
          resolve(file);
        }
        return response.json();
      }).then((data) => {
        if (data.error) {
          errorElement.innerText = data.error;
          const error = new Error();
          error.name = 'upload fail';
          error.message = `Die Datei ${this.file.name} konnte nicht hochgeladen werden.`;
          reject(error);
        }
      }).catch((exception) => {
        console.error(exception);
        errorElement.innerText = 'Datei konnte nicht hochgeladen werden.';
        const error = new Error();
        error.name = 'upload fail';
        error.message = `Die Datei ${this.file.name} konnte nicht hochgeladen werden.`;
        reject(error);
      });
    });
  }
}

export default File;
