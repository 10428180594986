class ImagesLoopImages {
  constructor(element) {
    const pictureElements = element.querySelectorAll('picture');
    let i = 0;

    function updateImages(exceptPictureElement) {
      [...pictureElements].forEach((pictureElement) => {
        if (pictureElement === exceptPictureElement) {
          pictureElement.classList.remove('is-hidden');
        } else {
          setTimeout(() => {
            pictureElement.classList.add('is-hidden');
          }, 100);
        }
      });
    }

    function showImage() {
      i += 1;
      if (i > pictureElements.length) {
        i = 0;
      }
      const imageToShow = pictureElements[i];
      updateImages(imageToShow);
    }

    updateImages();

    setInterval(showImage, 1200);
  }
}

[...document.querySelectorAll('.images__loop-images')].forEach(element => new ImagesLoopImages(element));
