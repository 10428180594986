// very wide image which moves on scroll and mouse/touch movement
// arbeiten/speidel

import Component from './Component';
import Picture from './Picture';

class Werbebande extends Component {
  constructor(element) {
    super(element);

    const advertBoard = this;
    const container2Element = element.querySelector('.werbebande__container-2');
    const imgContainerElement = container2Element.querySelector('div');
    let mouseMoveIntensity = 2;
    let isLoading = false;
    let isMouseDown = false;
    let mouseXStart = 0;
    let mouseXDelta = 0;
    let scrollTransformX = 0;
    let mousemoveTransformXStart = 0;
    let mousemoveTransformX = 0;
    let transformX = 0;
    let transformXDestination = 0;
    let minTransform = 0;
    const maxTransform = 0;
    let isDrawing = false;
    let animationFrame;
    const pictures = [];

    if ('ontouchstart' in document.documentElement) {
      mouseMoveIntensity = 3.5;
    }

    function getAndSetWidthForImgContainer() {
      let width = 0;
      [...container2Element.querySelectorAll('img')].forEach((imgElement) => {
        width += imgElement.offsetWidth;
      });
      imgContainerElement.style.width = `${width}px`;
      return width;
    }

    function getMinTransform() {
      const width = getAndSetWidthForImgContainer();
      return width - container2Element.offsetWidth;
    }

    function draw() {
      isDrawing = true;
      const rect = element.getBoundingClientRect();
      const newScrollTransformX = ((rect.top - window.innerHeight) * 2);

      // check for min and max value
      if (isMouseDown) {
        const newMousemoveTransformX = mousemoveTransformXStart + mouseXDelta;
        if (newScrollTransformX + (newMousemoveTransformX * mouseMoveIntensity) > minTransform * -1
          && newScrollTransformX + (newMousemoveTransformX * mouseMoveIntensity) < maxTransform) {
          scrollTransformX = newScrollTransformX;
          mousemoveTransformX = newMousemoveTransformX;
        }
      } else if (newScrollTransformX + (mousemoveTransformX * mouseMoveIntensity) > minTransform * -1
        && newScrollTransformX + (mousemoveTransformX * mouseMoveIntensity) < maxTransform) {
        scrollTransformX = newScrollTransformX;
      }

      transformXDestination = scrollTransformX + (mousemoveTransformX * mouseMoveIntensity);
      transformX += (0.1 * (transformXDestination - transformX));
      transformX = Math.round(transformX * 100) / 100;
      // imgContainerElement.style.transformOrigin = `${(transformX / minTransform) * -100}% center`;
      container2Element.style.transform = `translateX(${transformX}px)`;
      animationFrame = requestAnimationFrame(draw);
    }
    animationFrame = requestAnimationFrame(draw);

    function onLoad(pictureElement) {
      pictureElement.classList.remove('is-not-loaded');
      setTimeout(() => {
        pictureElement.classList.remove('has-preloader');
      }, 300);
      minTransform = getMinTransform();
      if (!isDrawing) {
        animationFrame = requestAnimationFrame(draw);
      }
    }

    function load() {
      isLoading = true;
      pictures.forEach((picture) => {
        picture.loadImage();
        const imgElement = picture.element.querySelector('img');
        picture.loadImage();
        imgElement.addEventListener('load', () => {
          onLoad(picture.element);
        });
        if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident') > 0) {
          onLoad(picture.element);
        }
        if (imgElement.complete) {
          onLoad(picture.element);
        }
      });
    }


    function onScroll() {
      if (advertBoard.willSoonBeInView) {
        if (!isLoading) {
          load();
        }
        if (!isDrawing) {
          animationFrame = requestAnimationFrame(draw);
        }
      } else {
        cancelAnimationFrame(animationFrame);
        isDrawing = false;
      }
    }

    function onMouseDown(event) {
      if (event.type !== 'mousedown' || (event.which === 1 && !event.ctrlKey && !event.metaKey && !event.altKey)) {
        if (event.type === 'mousedown') {
          event.preventDefault();
        }
        isMouseDown = true;
        mouseXStart = event.pageX;
        mousemoveTransformXStart = mousemoveTransformX;
        element.classList.add('is-mouse-down');
      }
    }
    function onMouseMove(event) {
      if (isMouseDown) {
        mouseXDelta = event.pageX - mouseXStart;
        if (event.type === 'touchmove' && (mouseXDelta > 2 || mouseXDelta < -2)) {
          // isSwiping = true;
          event.preventDefault();
        }
      }
    }
    function onMouseUp() {
      isMouseDown = false;
      mouseXDelta = 0;
      element.classList.remove('is-mouse-down');
    }

    const pictureElements = element.querySelectorAll('picture');
    [...pictureElements].forEach((pictureElement) => {
      pictures.push(new Picture(pictureElement));
    });

    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onScroll);
    element.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    element.addEventListener('touchstart', onMouseDown, { passive: false });
    window.addEventListener('touchmove', onMouseMove, { passive: false });
    window.addEventListener('touchend', onMouseUp);
    window.addEventListener('resize', () => {
      minTransform = getMinTransform();
    });
  }
}

[...document.querySelectorAll('.werbebande')].forEach(element => new Werbebande(element));
