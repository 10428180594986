// picture elements inside of .images
import Picture from './Picture';

class Image extends Picture {
  constructor(element) {
    const pictureElement = element.querySelector('picture');
    super(pictureElement);

    const image = this;

    // element.offsetParent !== null checks if image is not set to display: none or similar
    function checkIfContentShouldLoad() {
      if (!image.isLoading
        && image.willSoonBeInView
        && pictureElement.offsetParent !== null) {
        image.loadImage();
        window.removeEventListener('resize', checkIfContentShouldLoad);
        window.removeEventListener('scroll', checkIfContentShouldLoad);
      }
    }
    if (image.hasPreloader === true && pictureElement.classList.contains('is-not-loaded')) {
      window.addEventListener('resize', checkIfContentShouldLoad);
      window.addEventListener('scroll', checkIfContentShouldLoad, { passive: true });
      setTimeout(checkIfContentShouldLoad, 300); // delay to save performance
    }
  }
}


[...document.querySelectorAll('.image')].forEach(element => new Image(element));
