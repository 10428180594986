// picture elements inside of .images
import Picture from './Picture';

class ImagesPicture extends Picture {
  constructor(element) {
    super(element);

    const imagesPicture = this;

    // element.offsetParent !== null checks if image is not set to display: none or similar
    function checkIfContentShouldLoad() {
      if (!imagesPicture.isLoading
        && imagesPicture.willSoonBeInView
        && element.offsetParent !== null) {
        imagesPicture.loadImage();
        window.removeEventListener('resize', checkIfContentShouldLoad);
        window.removeEventListener('scroll', checkIfContentShouldLoad);
      }
    }
    if (imagesPicture.hasPreloader === true && element.classList.contains('is-not-loaded')) {
      window.addEventListener('resize', checkIfContentShouldLoad);
      window.addEventListener('scroll', checkIfContentShouldLoad, { passive: true });
      setTimeout(checkIfContentShouldLoad, 300); // delay to save performance
    }
  }
}


[...document.querySelectorAll('.images picture')].forEach(element => new ImagesPicture(element));
