class MemoryFigure {
  constructor(element) {
    const divElement = element.querySelector('div');
    let figureRect = element.getBoundingClientRect();
    let rotateY = 0;
    let rotateX = 0;
    let animationFrame = 0;

    function onClick() {
      element.classList.toggle('is-flipped');
    }

    function draw() {
      let _rotateY = rotateY;
      if (element.classList.contains('is-flipped')) {
        _rotateY -= 180;
      }
      divElement.style.transform = `rotateY(${_rotateY}deg) rotateX(${rotateX}deg)`;
      animationFrame = requestAnimationFrame(draw);
    }


    function onMousemove(event) {
      figureRect = element.getBoundingClientRect();
      const clientX = event.clientX ? event.clientX : event.touches[0].clientX;
      const clientY = event.clientY ? event.clientY : event.touches[0].clientY;
      const divWidth = divElement.offsetWidth;
      const divHeight = divElement.offsetHeight;
      rotateY = (clientX - figureRect.left - (divWidth * 0.5)) * (12 / divWidth);
      rotateX = (clientY - figureRect.top - (divHeight * 0.5)) * (4 / divHeight);
    }

    element.addEventListener('touchmove', onMousemove);
    element.addEventListener('mousemove', onMousemove);

    if (!('ontouchstart' in document.documentElement)) {
      divElement.addEventListener('mouseenter', () => {
        animationFrame = requestAnimationFrame(draw);
      });
      divElement.addEventListener('mouseleave', () => {
        divElement.style.transform = '';
        cancelAnimationFrame(animationFrame);
      });
    } else {
      divElement.addEventListener('touchstart', () => {
        animationFrame = requestAnimationFrame(draw);
      }, { passive: false });
      document.addEventListener('touchend', () => {
        divElement.style.transform = '';
        cancelAnimationFrame(animationFrame);
      });
    }

    divElement.addEventListener('click', onClick);
  }
}


[...document.querySelectorAll('figure.is-memory')].forEach(element => new MemoryFigure(element));
