class JobsInterested {
  constructor(element) {
    const jobsInterested = this;

    const formElement = element.querySelector('form');
    const overlayElement = element.querySelector('.form__overlay');
    const successElement = element.querySelector('.form__success');
    const inputElements = element.querySelectorAll('input, textaraea');
    const sonstigesCheckboxElement = element.querySelector('input[value="sonstiges"]');
    const sonstigesLabelElement = element.querySelector('#label-sonstiges');
    const sonstigesInputElement = sonstigesLabelElement.querySelector('input');

    const headers = new Headers(); // FOR DEBUG
    headers.append('Authorization', `Basic ${btoa('vorschau:apfelbaum')}`); // FOR DEBUG

    this.formElement = formElement;
    this.overlayElement = overlayElement;
    this.successElement = successElement;
    this.inputElements = inputElements;
    this.headers = headers;

    formElement.addEventListener('submit', (event) => {
      event.preventDefault();
      jobsInterested.submit();
    });
    sonstigesCheckboxElement.addEventListener('change', () => {
      sonstigesLabelElement.hidden = !sonstigesCheckboxElement.checked;
      sonstigesInputElement.required = sonstigesCheckboxElement.checked;
      jobsInterested.removeAllErrorMessages();
    });
  }

  submit() {
    const jobsInterested = this;
    const {
      formElement,
      headers,
    } = jobsInterested;
    const url = '/api/jobs-interested/submit';
    jobsInterested.showOverlay('Daten werden übertragen');
    jobsInterested.removeAllErrorMessages(400);

    fetch(url, {
      headers,
      method: 'POST',
      body: new FormData(formElement),
    }).then(response => response.json()).then((data) => {
      console.log(data);
      jobsInterested.hideOverlay();
      if (data.code === 400) {
        jobsInterested.makeFormActive();
        jobsInterested.showError(data.message);
      } else {
        jobsInterested.showSuccess();
      }
    });
  }

  showError(text) {
    const { formElement } = this;
    const submitElement = formElement.querySelector('button[type="submit"]');
    const inputErrorElement = document.createElement('div');
    inputErrorElement.classList.add('error');
    inputErrorElement.innerText = text;
    submitElement.parentNode.insertBefore(inputErrorElement, submitElement);
  }

  removeAllErrorMessages(delay = 0) {
    const { formElement } = this;
    const inputErrorElements = formElement.querySelectorAll('div.error');
    setTimeout(() => {
      [...inputErrorElements].forEach((inputErrorElement) => {
        inputErrorElement.parentElement.removeChild(inputErrorElement);
      });
    }, delay);
  }

  makeFormInactive() {
    const { inputElements } = this;
    [...inputElements].forEach((inputElement) => {
      inputElement.readOnly = true;
    });
  }

  makeFormActive() {
    const { inputElements } = this;
    [...inputElements].forEach((inputElement) => {
      inputElement.readOnly = false;
    });
  }

  showSuccess() {
    const { formElement, successElement } = this;
    successElement.style.display = 'block';
    formElement.style.height = `${formElement.offsetHeight}px`;
    formElement.style.overflow = 'hidden';
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        successElement.classList.add('is-visible');
        const successElementTop = successElement.getBoundingClientRect().top;
        const top = (successElementTop + document.scrollingElement.scrollTop) - 80;
        formElement.style.height = `${successElement.offsetHeight}px`;
        window.scroll({ top, left: 0, behavior: 'smooth' });
      });
    });
  }

  showOverlay(text) {
    const { overlayElement } = this;
    if (text) {
      const spanElement = overlayElement.querySelector('span');
      spanElement.innerHTML = text;
    }
    overlayElement.style.display = 'flex';
    this.makeFormInactive();
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        overlayElement.classList.add('is-visible');
      });
    });
  }

  hideOverlay() {
    const { overlayElement } = this;
    function onTransitionend(event) {
      if (event.target) {
        overlayElement.removeEventListener('transitionend', onTransitionend);
        overlayElement.style.display = '';
      }
    }

    overlayElement.removeEventListener('transitionend', onTransitionend);
    overlayElement.addEventListener('transitionend', onTransitionend);
    overlayElement.style.display = 'flex';
    overlayElement.classList.remove('is-visible');
  }
}

const jobsInterestedElements = document.querySelectorAll('.jobs__interested');
[...jobsInterestedElements].forEach(element => new JobsInterested(element));
